import React from "react-dom"
import { useState, useEffect } from 'react';

import styles from "./Header.module.css"


export default function(){
    const initStyle = () => ({ zIndex: 0 });

    const [style, setStyle] = useState(initStyle);

    useEffect(() => {
      // Вот здесь можно менять значения - zIndex поменяется через секунду
      const timer = setTimeout(() => setStyle({ zIndex: 3000 }), 4500);
  
      // Надо не забыть почистить счетчик setTimeout
      return () => clearTimeout(timer);
    }, []);



    return(       
            <header className={styles.header + " navbar navbar-expand-lg navbar-light fixed-top " } style = {style}>
                <a className="navbar-brand" width ="100px" href="#">
                    <img src="./img/header_logo.png" className={styles.header__logo + " d-inline-block align-top"} alt="Центр кадастровых работ лого"/>
                    <span className = {styles.header__title}>
                        Центр кадастровых работ
                    </span>
                </a>
               
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav mx-auto">
                        <a className="nav-item nav-link mr-4" href="#services"><span className = {styles.nav__text}>Наша деятельность</span> </a>
                        <a className="nav-item nav-link mr-4" href="#wwu"><span className = {styles.nav__text}>Работют с нами</span></a>
                        <a className="nav-item nav-link " href="#about-us"><span className = {styles.nav__text}>О нас</span></a>                        
                    </div>
                    <span className = {styles.header__info}>
                        Пн-Пт: 10:00 – 18:00 <br/>
                        +7 (982) 44 18 318  |  +7 (912) 88 18 318  <br/>
                        kadastr-perm@inbox.ru
                    </span> 
                </div>
                
            </header>
    )
}