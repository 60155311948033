import React from "react-dom"
import styles from './Footer.module.css'

export default function() {
    return(
        <footer className = {styles.footer}>
            <p className = {styles.footer__creater}>
                Made by <a className = {styles.footer__link} href = "https://t.me/MarEeee13">Margin Efim</a>
            </p>
        </footer>
    )
}