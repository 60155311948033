import {OPEN_FORM, CHANGE_LOADING_STATE} from './types'
const initialState = {
    formState: false,
    isLoading: false
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_FORM:
            return {...state, formState:action.payload}
        case CHANGE_LOADING_STATE:
            return {...state, isLoading:action.payload}
        default:
            return state;
    }
}



export {reducer}