import React from 'react-dom'

import img from '../../img/services-card/3.jpg'
import styles from './AboutUs.module.css'

export default function() {
    return(
        <section id = "about-us" className = {styles.about}>
            <div className = {styles.about__container}>
                <div className = {styles.about__discription}>
                    <h2 className = {styles.about__title}>О нашей организации</h2>
                    <div className = {styles.about__line}></div>
                    <ul className = {styles.about__paragraph}>
                        Компания создана в 2012 году с целью профессионального оказания следующих услуг:
                        <br/>
                        <br/>
                        <li className = {styles.paragraph__string}>Оформление документов на недвижимость</li><br/>
                        <li className = {styles.paragraph__string}>Разработка проектов планировки и межевания</li><br/>
                        <li className = {styles.paragraph__string}>Установление охранных и санитарно-защитных зон</li><br/>
                        <li className = {styles.paragraph__string}>Межевание земельных участков, перераспределение земель, установление границ, объединение и раздел земельных участков</li><br/>
                        <li className = {styles.paragraph__string}>Смена категории и вида разрешенного использования земельных участков</li><br/>
                        <li className = {styles.paragraph__string}>Получение разрешений на строительство и ввод объектов в эксплуатацию, согласование перепланировок.</li><br/>
                        
                        <strong>Мы работаем с юридическими и физическими лицами!</strong>
                    </ul>
                </div>
                <img className =  {styles.about__img} src = {img} alt = "Кадастровое агенство"></img>
            </div>
        </section>
    )
}