import React from "react-dom"
import styles from "./WhereWeAreTheBest.module.css"


export default function (){
    return(
        <section className = {styles.the_best}>
            <div className = {styles.the_best__container}>                
                <h2 className = {styles.the_best__title} >Описание санитарно-защитных зон</h2>
                <h2 className = {styles.the_best__title} >Технические планы</h2>
                <div className = {styles.the_best__row_info} >
                    <h2 className = {styles.the_best__title}>Изыскания</h2>
                    <div className = {styles.the_best_assistant_text}>
                            <span className = {styles.the_best__text} >То, что мы делаем лучше остальных</span>
                            <span className = {styles.the_best__post_text} >Мы профессионалы своего дела</span>
                    </div>
                </div>
            </div>
            
            
        </section>
    )
}