import React from 'react-dom'
import {Button} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {openForm} from "../redux/action" //delete?
import {connect,useDispatch} from 'react-redux';
import emailjs from 'emailjs-com';

import styles from './Form.module.css'

const MyForm = ({onForm}) =>{
    const service_id = 'service_tsyb807';
    const template_id = 'template_45u22qc';
    const user_id = 'user_jhs3TDijfHiul7Gy5rm4k';
    const dispatch = useDispatch();

    
    function closeButtonHandler(){ 
        document.getElementById("form").reset();       
        dispatch(openForm(false)); 
            
    }
    
    function sendEmail(e){
        e.preventDefault();
        emailjs.sendForm(service_id, template_id, e.target, user_id)
          .then((result) => {
                closeButtonHandler();
                console.log(result.text);
          }, (error) => {
                console.log(error.text);
          });
      }
  
    
    return(
        <form id = "form" className = {`${styles.form} ${onForm ? ' ': styles.form_closed}`} onClick={closeButtonHandler} onSubmit={sendEmail}>
            <div className = {styles.form__profile} onClick={(e)=>{e.stopPropagation()}} >
                <button onClick = {closeButtonHandler} className = {styles.form__close_button} aria-label="Close form" type="reset"></button>
                <h2 className = {styles.form__title}>Ответим на любые вопросы и поможем с выбором!</h2>
                <span className = {styles.form__text}>
                    Просто оставьте заявку и мы перезвоним в течение 5 минут
                    Или обращайтесь по телефону +7 (912) 881 83 18
                </span>

                <Form.Group controlId="formBasicName" >
                    <Form.Control
                        className = {styles.input__elem}
                        type="text"
                        placeholder="Введите ваше имя"
                        name = 'name'
                        
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPhone" >
                    <Form.Control 
                        className = {styles.input__elem} 
                        type="tel" 
                        placeholder="Введите ваш номер телефона"
                        name = "phone_number"
                        
                    />
                </Form.Group>
                
                <Button variant="primary" className = {styles.form__submit_button} type = "submit" >
                    Заказать звонок
                </Button>
            </div>
        </form>
    )
}

const mapStateToProps = state => {   
    return {
        onForm: state.items.formState,
        isLoading: state.items.isLoading //delete?
    }
}


const mapDispatchToProps = { //delete
    openForm:openForm
}

export default connect(mapStateToProps,null)(MyForm)