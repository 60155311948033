import React from 'react-dom'
import { connect, useDispatch } from 'react-redux';
import {openForm} from "../redux/action"
import styles from './FixedElements.module.css'
import {useEffect} from 'react'


const FixedElements  = ({item}) =>{
    const dispatch = useDispatch();
    
    function handleClick() {     
        dispatch(openForm(true));
    }

    useEffect(() =>{

    });

    return(
        <div>
            <button onClick={handleClick}  type="button" id = "active_btn" className= {styles.btn__order_call + " btn btn-primary"} style = {item}>Заказать звонок</button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        onForm: state.items.formState
    }
}

export default connect(mapStateToProps, null)(FixedElements)