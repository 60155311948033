import React from "react-dom"
import styles from "./Loader.module.css"

export default function(){
    return(
        <section className = {styles.loader}>
            <svg  viewBox="0 0 1280 720" fill="none" preserveAspectRatio="none" className = {styles.loader_svg} >
                <defs>
                    <polygon id="fig"
                                    className={styles.tringl_obj} //??????
                                    style={{stroke:'black', fill:' rgb(235, 11, 11)', backgroundColor: 'black'}}
                                    transform="translate(-185,-163)"
                                    points="190.492 149.86 186.232 156.783 195.951 174.49 155.412 174.823 160.204 182.811 209.797 182.744"
                                    >
                                    <animateTransform
                                    attributeName="transform"
                                    type="translate"
                                    
                                    values = "-185,-163; -145, -150; -185,-163;"
                                    keyTimes="0; 0.7; 1"
                                    dur = "6s"
                                    fill = "freeze"
                                    />
                        </polygon>

                    <mask id = "mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="#fff" />

                        <path 
                            d="M37.5 0L74.3061 63H0.69392L37.5 0Z"  
                            fillOpacity="1" 
                            fill = "red"
                            
                            transform-origin = "38px 40px"
                            transform = " translate(600, 360) scale(0)"
                        > 
                            <animateTransform    
                                attributeName="transform"
                                type="translate"
                                from = "600,360"
                                to = "600,360"
                                repeatCount="1"
                                fill = "freeze"
                                begin = "2s"
                            /> 
                            <animateTransform  
                                id = 'scal'  
                                attributeName="transform"
                                type="scale"
                                values = "1;20; 40"
                                keySplines="0.5 0 0.5 1;
                                            0.5 0 0.5 1;"
                                calcMode="spline"
                                dur = "2s"
                                repeatCount="1"
                                additive = "sum"
                                fill = "freeze"
                                begin = "rot.end"
                            /> 
                        </path>
                    </mask>
                </defs>
                <rect className = {styles.loader__background}  fillOpacity="1" fill="black" x = "0" y = "0" mask="url(#mask)"/>
                <g  x='0' y='0' transform = "translate(638, 401) scale(1)">
                    <use href="#fig"></use>
                    <use href="#fig" transform="rotate(-120)"></use>
                    <use href="#fig" transform="rotate(120)"></use>
                    <animateTransform 
                        id = "rot"   
                        attributeName="transform"
                        type="rotate"
                        values="0;250;0"
                        dur = "2s"
                        additive="sum"
                    />

                    <animateTransform 
                        attributeName="transform"
                        type="translate"
                        to = "638, 190"
                        dur = "2s"
                        repeatCount="1"
                        fill = "freeze"
                        begin = "rot.end"
                    />
                    
                    <animateTransform 
                        id = "scal"   
                        attributeName="transform"
                        type="scale"
                        values = "1;16; 40; 2.5"
                        keySplines="0.5 0 0.5 1;
                                    0.5 0 0.5 1;
                                    0.5 0 0.5 1;"
                        calcMode="spline"
                        dur = "2.8s"
                        repeatCount="1"
                        additive="sum"
                        fill = "freeze"
                        begin = "rot.end"
                     />
                </g>
            </svg>
    </section>  
    )
}