import {OPEN_FORM, CHANGE_LOADING_STATE} from "./types";


export function openForm(style){
    return{
        type: OPEN_FORM,
        payload: style
    }
}

export function changeLoadingStatus(isLoading){
    return{
        type: CHANGE_LOADING_STATE,
        payload: isLoading
    }
}
