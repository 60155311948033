import './App.css';
import {useState,useEffect} from 'react'
import FixedElements from './components/FixedElements/FixedElements';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import WhereWeAreTheBest from './components/WhereWeAreTheBest/WhereWeAreTheBest';
import OurServices from './components/OurServices/OurServices';
import WorkWithUs from './components/WorkWithUs/WorkWithUs'
import Сonsultation from './components/Сonsultation/Сonsultation';
import AboutUs from './components/AboutUs/AboutUs'
import ContactWithUs from './components/ContactWithUs/ContactWithUs'
import Form from './components/Form/Form';
import Loader from './components/Loader/Loader';
import Footer from './components/Footer/Footer';
import { connect } from 'react-redux';

// import preloader from 

function App({isLoading}) {

  const [isScrool, setScroll] = useState({ opacity: 0 });
  const [position, setPosition] = useState({position: "fixed"});

  function onScrollList(){
    setScroll({ opacity: 1, visibility: "visible" });
  }
  useEffect(() => {
      // Вот здесь можно менять значения - zIndex поменяется через секунду
    const timer = setTimeout(() => setPosition({position: "static"}), 4500);

    return () => clearTimeout(timer);
  }, []);
  

  return (
    <>      
    <div className="page" style = {position} onWheel={onScrollList}>
      <Loader/>
      <FixedElements item={isScrool}/>
      <Header/>
      <Main/>
      

      <WhereWeAreTheBest/>
      <OurServices/>
      <WorkWithUs/> 
      <Сonsultation/>
      <AboutUs/> 
      <ContactWithUs/> 
      <Footer/>
      <Form/>
    </div>

    </>
  );
}
const mapStateToProps = state => {    
  
  return {
    isLoading: state.items.isLoading
  }
}

export default connect(mapStateToProps, null)(App)
