import React from "react-dom"
import { connect, useDispatch } from 'react-redux';
import {openForm} from "../redux/action"
import img1 from "../../img/services-card/1.jpg"
import img2 from "../../img/services-card/2.jpg"
import img3 from "../../img/services-card/3.jpg"
import img4 from "../../img/services-card/4.jpg"
import img5 from "../../img/services-card/5.jpg"
import img6 from "../../img/services-card/6.jpg"
import styles from "./OurServices.module.css"

const Services = () =>{
    const dispatch = useDispatch();
    function handleClick() {        
        dispatch(openForm(true));
      }

    const cards  = [
        {
            title: "Санитарно-защитные зоны",
            discription: "Оформление санитарно-защитных зон",
            price: "От 10000руб.",
            alt: "",
            img: img1
        },
        {
            title: "Технический план",
            discription: "Составление технических планов",
            price: "От 3000руб.",
            alt: "",
            img: img2
        },
        {
            title: "Изыскания",
            discription: "Выполнения работ по изысканию",
            price: "До 8000руб.",
            alt: "",
            img: img3
        },
        {
            title: "Топографическая съемка",
            discription: "Оформление топографической съемки",
            price: "До 8000руб.",
            alt: "",
            img: img4
        },
        {
            title: "Межевание",
            discription: "Выполнение работ по межеванию",
            price: "От 3500руб.",
            alt: "",
            img: img5
        },
        {
            title: "Недвижимость",
            discription: "Оценка объектов недвижмости",
            price: "От 3000руб.",
            alt: "",
            img: img6
        }
    ] 

    return(
        <section id = "services" className = {styles.services}>
            <div className = {styles.services__cards}>
                {
                    cards.map((card, id)=>{
                        return (
                            <div key = {id} className = {styles.services__card}>
                                <div className = {styles.card__content}> 
                                    <h3 className = {styles.card__title}> {card.title} </h3>
                                    <span className = {styles.card__discription}> {card.discription} </span>
                                    {/* <span className = {styles.card__price}> {card.price} </span> */}
                                    <button type="button" className= {styles.card__btn + " btn btn-light"} onClick={handleClick}>Заказать</button>
                                </div>
                                <div className = {styles.card__background} ></div>
                                <img className = {styles.card__image}  src = {card.img} alt = {card.alt}/>  
                            </div>
                        )
                    })
                }
            </div> 
        </section>
    )
}
const mapStateToProps = state => { 
    return {
        onForm: state.items.formState
    }
}

export default connect(mapStateToProps, null)(Services)