import React from "react"
import {connect} from 'react-redux';

import styles from"./Main.module.css"
import myVideo from "../../img/video.webm"


const Main =  () => {
    return(
        <section className = {styles.main}>
            <div  className = {styles.main__content}>
                <span className = {styles.main__pretitle}>Общество с ограниченной ответственностью</span>
                <h1 className = {styles.main__title}>Центр кадастровых работ</h1>
                <span className = {styles.main__info}> ОГРН:1125904015600/ИНН:5904276393</span>
               
            </div>

            <div className = {styles.main__background}></div>

            <video className = {styles.main__video} preload="auto" autoPlay= {true} loop="loop" muted="muted" volume="0">
                <source src={myVideo} type="video/webm" id="source-video"/>
            </video>
        </section>
    )
}

export default connect(null,null)(Main)