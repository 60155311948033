import React from "react-dom"
import vk_logo from "../../img/contactWithUsLogos/vk_logo.svg"
import inst_logo from "../../img/contactWithUsLogos/istagram_logo.svg"
import styles from './ContactWithUs.module.css'

export default function() {
    return(
        <section className = {styles.contacts}>
           <iframe className = {styles.contacts__map} src="https://yandex.ru/map-widget/v1/?um=constructor%3A96ec1e396aaaeb09d263f153f68b4fca6a499802983c4178c599b87d8f99599c&amp;source=constructor" width="70%"  frameBorder="0"></iframe>
            <div className = {styles.contacts__info}>
                <h2 className = {styles.contacts__title}>Остались вопросы?<br/> Свяжитесь с нами!</h2>
                <p className = {styles.contacts__phones}>
                     Тел.:<br/>
                     +7 (982) 44 18 318<br/>
                     +7 (912) 88 18 318                
                </p>
                <p className = {styles.contacts__email}>
                     Email:<br/>
                     <span className = {styles.email__link}>kadastr-perm@inbox.ru</span>                        
                </p>               
                <span className = {styles.contacts__adress}>614068, г.Пермь, ул.Борчанинова, дом 3</span>
                <span className = {styles.contacts__schedule}>Пн-Пт: c 10:00 до 18:00</span>
                <span className = {styles.contacts__company}>
                    ООО Центр кадастровых работ<br/>
                    ИНН:5904276393<br/>
                    ОГРН:1125904015600 
                </span>
                <div className = {styles.contacts__social_networks}>
                    <a href = "https://vk.com/"><img className = {styles.social_network} src = {vk_logo} alt = "Логотип ВК"/></a> 
                    <a href = "https://www.instagram.com/"><img className = {styles.social_network} src = {inst_logo} alt = "Логотип инст"/></a>
                </div>
            </div>
            
        </section>
    )
}