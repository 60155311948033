import React from 'react-dom'
import Carousel from 'react-elastic-carousel';

import img00 from "../../img/carusel/00.svg"
import img0 from "../../img/carusel/0.svg"
import img1 from "../../img/carusel/1.svg"
import img2 from "../../img/carusel/2.jpg"
import img3 from "../../img/carusel/3.svg"
import img4 from "../../img/carusel/4.svg"
import img5 from "../../img/carusel/5.jpg"
import img6 from "../../img/carusel/6.jpg"
import img7 from "../../img/carusel/7.png"
import img8 from "../../img/carusel/8.jpg"

import styles from "./WorkWithUs.module.css"


export default function() {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 4 },
        { width: 1750, itemsToShow: 4 },
      ];
    return(
        
        <section id = "wwu" className = {styles.wwu}>
            <div className = {styles.wwu__container}>
                <h1 className = {styles.wwu__title}>Уже многие годы доверяют нам!</h1>
                <div className = {styles.wwu__post_title}>
                    <span className = {styles.post_title_first}>Мы делаем качественно, <br/> быстро и четко</span>
                    <span className = {styles.post_title_second}>Наши партнеры</span>
                </div>
                
            </div>
            <Carousel
                              
                easing={"ease"}
                enableAutoPlay
                autoPlaySpeed = {2000}
                transitionMs = {500}
                enableTilt = {true}               
                breakPoints = {breakPoints}>
                
               {/* {
                images.map((elem)=>{
                    <img src = {img1} alt = "" className = "carusel-item" />
                })
               } */}
               <img src = {img00} alt = "" className = {styles.carusel__item} />                
                
                <img src = {img0} alt = "" className = {styles.carusel__item} />
                
                <img src = {img1} alt = "" className = {styles.carusel__item} />                
                
                <img src = {img2} alt = "" className = {styles.carusel__item} />
                
                <img src = {img3} alt = "" className = {styles.carusel__item} />
                
                <img src = {img4} alt = "" className = {styles.carusel__item} />

                <img src = {img5} alt = "" className = {styles.carusel__item} />

                <img src = {img6} alt = "" className = {styles.carusel__item} />

                <img src = {img7} alt = "" className = {styles.carusel__item} />

                <img src = {img8} alt = "" className = {styles.carusel__item} />
                
            </Carousel>
            
        </section>
    )
}